.float-button {
    transition: all 0.3s !important;
    &:hover {
        margin-bottom: 5px;
        box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.2);
    }
}

.card {
    &-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        flex-wrap: wrap;
    }
}

.printableTicket {
    background-color: #ffffff00;
    color: black !important;
    font-size: 12px;
    padding: 0 !important;
    margin: auto;
    font-weight: bold;
    img {
        filter: brightness(0);
    }

    table {
        max-width: 100px;
    }

    > * {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
        background-color: #ffffff00 !important;
    }

}

.printableArea {
    background-color: #ffffff00;
    display: none;
    margin: auto;
    width: 110px;

    > * {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
        background-color: #ffffff00 !important;
    }
}

@media print {
    .notPrintableArea {
        display: none !important;
        z-index: -1000;
    }

    .printableArea {
        display: block !important;
        z-index: 9999;
    }

    .modal-backdrop {
        display: none !important;
    }
}
