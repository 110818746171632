// Theme colors
$primary: #6d306b;

// Theme font
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,500;0,600;0,700;1,500;1,600;1,700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
$font-family-base: 'Montserrat';

@import "../node_modules/bootstrap/scss/bootstrap";

.image-container {
  max-width: 400px;
}

.login-form {
  max-width: 400px;
}
